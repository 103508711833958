import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import ContentCardsSection from '../components/ContentCardsSection'

class Features extends React.Component {
  render() {
    const siteData = this.props.data
    const featureData = this.props.data.allContentfulFeaturePost.edges
    let cards = []
    featureData.forEach(edge => {
      let post = edge.node
      cards.push({
        title: post.title,
        body: post.subtitle,
        url: `/features/${post.slug}`,
        image: 'https:' + post.image.fluid.src,
      })
    })
    return (
      <div>
        <Navbar
          color="white"
          spaced={true}
          data={siteData.allContentfulNavbar}
        />
        <SEO title="Akute EHR Features" />
        <ContentCardsSection
          color="white"
          size="small"
          title="Features"
          subtitle=""
          cards={cards}
        />
        <Footer
          color="light"
          size="medium"
          logo={siteData.allContentfulNavbar.edges[0].node.brandLogo}
          description={siteData.allContentfulFooter.edges[0].node.description}
          copyright={
            '© ' +
            siteData.allContentfulFooter.edges[0].node.companyName +
            ` ${new Date().getFullYear()}`
          }
          socialPages={siteData.allContentfulFooter.edges[0].node.socialPages}
          socialImages={siteData.allContentfulFooter.edges[0].node.socialImages}
          driftAppId={siteData.allContentfulFooter.edges[0].node.driftAppId}
        />
      </div>
    )
  }
}

export default Features

Features.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulFeaturePost {
      edges {
        node {
          title
          subtitle
          postDate
          image {
            fluid(maxWidth: 1500) {
              src
            }
          }
          keywords
          slug
          extraTitle
          extraSubtitle
          extraCta
          extraLink
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
